@import "styles/colors";
@import "styles/fonts";

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

}



.langs {
  position: absolute;
  top: 100%;
  border: 1px solid $grey-300;
  border-radius: 5px;
  background-color: $white;
  z-index: 103;
  .langs__item:hover {
    color: $white;
    background-color: $blue;
  }
}

.langs__item {
  padding: 0 0.5rem;
  text-transform: uppercase;
  display: flex;
  font-family: Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: $weight-bold;
  line-height: 2rem;

  &_selected {
    padding: 0;
  }
}

.arrow {
  margin-left: 3px;
}

.flag {
  margin-right: 6px;
}
