@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
    position: relative;
    width: 100%;
    min-width: 240px;
    background: $white;
    box-shadow: $shadow;
    overflow: hidden;
    z-index: 5;

    @media screen and (min-width: 992px) {
        animation: openModal 0.4s ease-out forwards;
        border-radius: 20px;
    }
}

@keyframes openModal {
    0% {
        transform: translateX(20px);
    }

    100% {
        transform: translateX(0);
    }
}

.avatar {
    flex-shrink: 0;
    position: relative;
    width: 73px;
    height: 73px;
    border-radius: 15px;
    overflow: hidden;


    &__placeholder {
        display: flex;
        height: 73px;
        align-items: center;
        border-radius: 15px;
        justify-content: center;
        color: $blue;
        font-size: 2rem;
        line-height: 2rem;
        font-weight: $weight-bold;
        text-transform: uppercase;
        background: $white;
    }
}

.user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 10px 27px;
    background: $blue;
    color: $white;

    @include media("max", "md") {

        padding: 10px 16px;
    }
}

.info {
    line-height: 24px;
    display: grid;
    word-break: break-word;
}

.name {
    font-size: 22px;

}

.email {
    font-size: 12px;
}

.menu {
    margin: 0;
    list-style: none;
    line-height: 2rem;
    color: $grey-700;

    &__item {
        border-bottom: 1px solid $grey-150;

        &:last-child {
            border-bottom: none;
        }

        a,
        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 12px 16px;
            line-height: 24px;
            background: transparent;
            border: none;
            color: inherit;
            transition: 0.15s all ease-out;

            &:hover {
                background: $blue;
                color: $white;
                transition: all 0.1s ease-in-out;
            }
        }


    }
}

.unread_messages {
    display: block;
    background: $red-dark;
    width: 0.6875rem;
    height: 0.6875rem;
    border-radius: 50%;
}
