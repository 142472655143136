@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
    position: absolute;
    top: rem(46);
    background-color: $white;
    width: 100%;
    right: 0;
    z-index: 3;
    border-radius: 0 0 rem(8) rem(8);
    box-shadow: $shadow;

    div {
        cursor: pointer;
    }
}

.item {
    display: flex;
    gap: 0.5rem;
    padding: 12px 16px;
    position: relative;
    border-bottom: 1px solid rgba($black, 0.2);

    &:last-child {
        border-bottom: none;
    }

    &.selected,
    &:hover {
        background: $grey-350;
        font-weight: 400;
    }
}

.selected {
    color: $blue;
}

.item_icon {
    img {
        object-fit: contain;
    }
}
