@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
  border-radius: 4px;
  height: 36px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  padding: rem(6) rem(16);
  cursor: pointer;
}

.primary {
  background: $blue;
  color: $white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

  &:hover{
    background: $dark-blue;
  }

  &.outline {
    color: $blue;
    border: 1px solid $blue;
    &:hover{
      background: transparent;
      border: 1px solid $dark-blue;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
      color: $dark-blue;
    }
  }

  &.underline {
    background: transparent;
    box-shadow: none;
    color: $blue;
    text-decoration: underline;
    &:hover {
      color: $dark-blue;
      text-decoration: none;
      background: transparent;
    }
  }
}

.dangerous {
  background: $red;
  border: 1px solid $red;
  color: $white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

  &.outline {
    color: $red;
  }

  &.underline {
    background: transparent;
    box-shadow: none;
    color: $red;
    text-decoration: underline;
    border: none;
    &:hover {
      color: $red;
      text-decoration: none;
      background: transparent;
    }
  }
}

.outline {
  box-shadow: none;
  background: transparent;
}