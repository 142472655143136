@import "styles/colors";
@import "styles/mixin";
@import "styles/fonts";

.overlayHidden {
    display: none;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}

.popup {
    position: relative;
    width: 400px;
    max-height: 90vh;
    background: $grey-175;
    padding: 40px 30px;
    border-radius: 12px;
    overflow: auto;
    scrollbar-width: none;
}

.title {
    font-size: rem(33);
    color: $dark;
    font-weight: $weight-bold;
    text-align: center;
    margin-bottom: rem(39);
}

.text {
    color: $grey-575;
    display: inline-block;
    margin-bottom: 14px;
}

.input {
    margin-bottom: rem(39);

    svg {
        width: 22px;
        stroke: #ffffff;
        stroke-width: 0.1;
    }
}

.message {
    display: flex;
    flex-direction: column;
    color: $dark-blue;
}

.message a {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 16px;
}

.btn {
    margin-top: 20px;
    width: 100%;
    background: $blue;
    border: none;
    border-radius: 8px;
    color: $white;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 19px;
    line-height: 19px;
}

.close {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 20px;
    top: 18px;
}

.close:hover {
    cursor: pointer;
}

.helpText {
    cursor: pointer;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

@include media("within", "sm") {
    .overlay {
        overflow: auto;
        background: white;
    }

    .popup {
        position: initial;
        height: 100vh;
    }
}


.error {
    margin-bottom: 1rem;
    text-align: center;
    color: $red;
}
