@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
  display: flex;
  height: 100%;
  margin: 0;
  min-height: calc(100vh - 58px - 58.8px);
}

.content_wrapper {
  width: 100%;
  margin: rem(25);
  max-width: 100%;
  overflow-y: auto;
}
