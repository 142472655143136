@import "styles/colors";
@import "styles/mixin";
@import "styles/fonts";

.title {
    font-size: rem(33);
    color: $dark;
    font-weight: $weight-bold;
    text-align: center;
    margin-bottom: rem(39);
}

.description {
    font-size: 1rem;
    text-align: center;
    margin: rem(39) 0;
    color: $grey-575;
}

.row {
    display: flex;
    gap: rem(19);
}

.block {
    flex-grow: 1;
}

.text {
    color: $grey-575;
    display: inline-block;
    margin-bottom: 14px;
}

.input {
    margin-bottom: rem(39);

    svg {
        width: 22px;
        stroke: #ffffff;
        stroke-width: 0.1;
    }
}

.message {
    display: flex;
    flex-direction: column;
    color: $dark-blue;
}

.message a {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 16px;
}

.btn {
    margin-top: 20px;
    width: 100%;
    background: $blue;
    border: none;
    border-radius: 8px;
    color: $white;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 19px;
    line-height: 19px;
}

.select {
    width: 100%;
    height: rem(40);
    font-size: 1rem;
    outline: none;
    border-radius: 26px;
    background: #FFFFFF;
    padding: 10px 24px;
    padding-right: 0;
    border: none;
    border-right: 1rem solid transparent;

    &:hover {
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
        z-index: 1;
    }
}



.helpText {
    cursor: pointer;
}

.textarea {
    resize: none;
    width: 100%;
    height: 5.375rem;
    font-size: 1rem;
    line-height: 1.5;
    outline: none;
    border: none;
    border-radius: 26px;
    background: #FFFFFF;
    padding: 10px 24px;

    &:focus-within {
        border: 1px solid $blue;
        filter: drop-shadow(0px 1px 1px rgba(39, 110, 172, 0.25));
        z-index: 1;
    }
}

.policy {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: rem(30);
    margin-top: auto;

    &__checkbox {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        border: 1px solid #D4D4D4;
        border-radius: 2px;
        margin-right: 8px;
        background: $white;
        cursor: pointer;
    }

    &__text {
        font-size: 15px;
        color: #626262;
        user-select: none;
    }

    &__link {
        color: #276EAC;
    }
}

.withError {
    position: relative;

    .input label,
    .input select,
    .input textarea {
        border: 1px solid $red;
        background-color: $light-pink;
    }
}

.error {
    margin-bottom: 1rem;
    text-align: center;
    color: $red;
}

@media screen and (max-width: 800px) {
    .row {
        flex-direction: column;
        gap: 0;
    }

    .input  {
        margin-bottom: rem(30);
    }
}
