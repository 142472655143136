@import "styles/colors";
@import "styles/fonts";

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 11px 43px;
    border: 1px solid $blue;
    border-radius: 45px;
    box-shadow: $multi-shadow;

    font-size: 1.1875rem;
    line-height: 22px;
    font-weight: $weight-medium;
    white-space: nowrap;

    color: $white;
    background-color: $blue;

    &:hover {
        background-color: $light-blue;
    }

    &:active {
        background-color: $dark-blue;
    }

    &:disabled {
        cursor: auto;
        background-color: $white;
        color: $grey-300;
        border: 1px solid $grey-300;
    }
}

.primary {
    color: $white;
    background-color: $blue;
    border: 1px solid $blue;

    &:hover {
        background-color: $light-blue;
    }

    &:active {
        background-color: $dark-blue;
    }
}

.secondary{
    color: $white;
    background-color: $grey-575;
    border: 1px solid $grey-575;

    &:hover {
        background-color: darken($grey-575, 10%);
    }

    &:active {
        background-color: darken($grey-575, 15%);
    }
}

.critical {
    color: $white;
    background-color: $red-dark;
    border-color: $red-dark;

    &:hover {
        background-color: darken($red-dark, 10%);
        border-color: darken($red-dark, 10%);
    }

    &:active {
        background-color: darken($red-dark, 15%);
        border-color: darken($red-dark, 15%);
    }
}
