@import "styles/mixin";

.logo {
  position: relative;
  width: 167px;
  height: 35px;
  margin-right: auto;

  @include media("max", "md") {
    width: 155px;
    height: 33px;
  }
  @media screen and (max-width: 340px) {
    width: 110px;
    height: 33px;
  }
}
