@import "styles/colors";
@import "styles/mixin";
@import "styles/fonts";

.header {
  margin: 0;
  padding: 0;
  background: transparent;

  &__top {
    height: 3.625rem;
    line-height: 3.625rem;
    background-color: #EAE8E8;
  }
}

.topWrapper {
  display: flex;
  align-items: center;
  height: 3.625rem;
}

.logo {
  display: flex;
  width: 305px;
  border-right: 1px solid $grey-100;
  height: 100%;
  align-items: center;
}

.center {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 24px;
}

.nav {

}

.user {
  font-family: $arial;
  font-size: 20px;
  font-weight: 700;
  color: #595959;
  letter-spacing: 0.01em;
}

.exit {
  width: 145px;
  padding: 0 0 0 24px;
  border-left: 1px solid $grey-200;
  &_button {
    width: 100%;
  }
}