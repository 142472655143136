@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
    width: 100%;
    display: flex;
}

.title {
    font-size: rem(24);
    line-height: 1.2;
    font-weight: $weight-bold;
    margin-bottom: rem(11);
    color: $grey-575;
    span {
        color: $red;
    }
}

.inputWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(40);
    font-size: 1rem;
    outline: none;
    border-radius: 26px;
    background: $white;
    padding: 0 rem(13);

    &:hover {
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
        z-index: 1;
    }

    &:focus-within {
        border: 1px solid $blue;
        filter: drop-shadow(0px 1px 1px rgba(39, 110, 172, 0.25));
        z-index: 1;
    }
}

.input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 11px;
}

.disabled {
    background: rgba($white, 0.4);

    &:hover {
        filter: none;
    }
}

.icon {
    transform: translateY(2px);

    &_prev {
        margin: auto 1rem;
    }

    &_post {
        margin: auto 1rem;
    }
}


.left,
.right {
    height: rem(42);
    border-radius: 26px;
    font-size: 16px;
    border: 1px solid $grey-250;

    .input {
        padding: 10px 6px;
    }
}
