@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";


.wrapper {
    display: flex;
    align-items: center;
    gap: 2.1875rem;
    padding: 12px 0;
}

.container {
    position: relative;
}

.profile {
    position: absolute;
    top: calc(100% + 12px);
    right: 215px;
}

.nav {
    position: relative;

    &__list {
        display: flex;
        gap: rem(40);
        list-style: none;
    }

    &__item {
        position: relative;
        font-size: 1.25rem;
        font-weight: $weight-bold;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;

        &>a {
            display: flex;

        }


        &_show {
            color: $blue;
        }

        &_signOut {
            display: none;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        align-self: stretch;
        font-size: 1.25rem;
        font-weight: $weight-bold;
        background: transparent;
        padding: 0;
        border: 0;
    }

    &__icon {
        margin-right: 0.5rem;
        transform: translateY(2px);
        position: relative;

        &_rev {
            transform: rotateX(180deg) translateY(3px);
        }
    }
}

.nav__item_category,
.nav__item_profile {
    .nav__button {
        position: relative;
        gap: 6px;

        &::after {
            content: "";
            flex-shrink: 0;
            width: 15px;
            height: 9px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjU0ODQgOC43NzQ4N0wwLjkxMDkgMS44MDg2MkMwLjgwMDA5NSAxLjY5MjM5IDAuNzM4MjgxIDEuNTM3OTYgMC43MzgyODEgMS4zNzczN0MwLjczODI4MSAxLjIxNjc5IDAuODAwMDk1IDEuMDYyMzYgMC45MTA5IDAuOTQ2MTI1TDAuOTE4NDAxIDAuOTM4NjI0QzAuOTcyMTE3IDAuODgyMDgxIDEuMDM2NzggMC44MzcwNTYgMS4xMDg0NCAwLjgwNjI4OUMxLjE4MDExIDAuNzc1NTIyIDEuMjU3MjggMC43NTk2NTYgMS4zMzUyOCAwLjc1OTY1NkMxLjQxMzI3IDAuNzU5NjU2IDEuNDkwNDQgMC43NzU1MjIgMS41NjIxMSAwLjgwNjI4OUMxLjYzMzc4IDAuODM3MDU2IDEuNjk4NDMgMC44ODIwODEgMS43NTIxNSAwLjkzODYyNEw4LjAwMjE1IDcuNDk4NjJMMTQuMjQ5NyAwLjkzODYyNEMxNC4zMDM0IDAuODgyMDgxIDE0LjM2OCAwLjgzNzA1NiAxNC40Mzk3IDAuODA2Mjg5QzE0LjUxMTQgMC43NzU1MjIgMTQuNTg4NSAwLjc1OTY1NiAxNC42NjY1IDAuNzU5NjU2QzE0Ljc0NDUgMC43NTk2NTYgMTQuODIxNyAwLjc3NTUyMiAxNC44OTM0IDAuODA2Mjg5QzE0Ljk2NSAwLjgzNzA1NiAxNS4wMjk3IDAuODgyMDgxIDE1LjA4MzQgMC45Mzg2MjRMMTUuMDkwOSAwLjk0NjEyNUMxNS4yMDE3IDEuMDYyMzYgMTUuMjYzNSAxLjIxNjc5IDE1LjI2MzUgMS4zNzczN0MxNS4yNjM1IDEuNTM3OTYgMTUuMjAxNyAxLjY5MjM5IDE1LjA5MDkgMS44MDg2Mkw4LjQ1MzQgOC43NzQ4N0M4LjM5NTAzIDguODM2MTQgOC4zMjQ4MiA4Ljg4NDkxIDguMjQ3MDQgOC45MTgyNEM4LjE2OTI2IDguOTUxNTcgOC4wODU1MiA4Ljk2ODc1IDguMDAwOSA4Ljk2ODc1QzcuOTE2MjggOC45Njg3NSA3LjgzMjU0IDguOTUxNTcgNy43NTQ3NiA4LjkxODI0QzcuNjc2OTggOC44ODQ5MSA3LjYwNjc3IDguODM2MTQgNy41NDg0IDguNzc0ODdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            transition: 0.15s ease-out;
        }

        &.active {
            pointer-events: none;

            &::after {
                transform: rotate(180deg);
            }
        }
    }
}

.unread_messages {
    display: block;
    background: $red-dark;
    position: absolute;
    width: rem(11);
    height: rem(11);
    border-radius: 50%;
    left: rem(-13);
    top: rem(16);
}
