@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
  background: $grey-175;
  width: 900px;
  max-width: 100%;
  min-height: 300px;
  max-height: 90vh;
  border-radius: 12px;
  padding: rem(10) rem(30) rem(26) rem(30);
  overflow: auto;
  scrollbar-width: none;
}

.heading {
  display: flex;
  margin-bottom: rem(10);
}

.stars {
  margin-bottom: rem(10);
  display: flex;
  cursor:pointer;
}

.close {
  cursor: pointer;
  font-size: rem(25);
  text-align: right;
}

.title {
  font-size: rem(20);
  font-weight: $weight-bold;
  flex-grow: 1;
}

.text {
  width: 100%;
  min-height: 200px;
  border-radius: 9px;
  border: 1px solid #9f9f9f;
  padding: 10px;
  margin-bottom: 10px;
}

.error {
  border: 1px solid #ff6f6f;
}

@include media("within", "md") {
  .container {
    height: auto;
  }
}

@include media("within", "sm") {
  .container {
    position: absolute;
    top: 0;
  }
}
