@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
  width: 100%;
  height: rem(40);
  user-select: none;
  cursor: pointer;
  transition: all 1s;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: $weight-medium;
}


.title {
  font-weight: $weight-bold;
  margin-bottom: 0.375rem;
}


.select {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 1.5;
  border: none;
  background: $white;
  padding: 10px 24px;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 24px;

  &:hover {
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
    z-index: 1;
  }

  &:focus-within {
    border: 1px solid $blue;
    filter: drop-shadow(0px 1px 1px rgba(39, 110, 172, 0.25));
    z-index: 1;
  }
}

.value {
  &_placeholder {
    color: $grey-500;
  }
}

.prevIcon {
  margin-right: 0.5rem;
  transform: translateY(2px);
}

.options {
  position: relative;
  z-index: 2;

  &_hidden {
    display: none;
  }

  &__wrapper {
    position: absolute;
    top: -0.25rem;
    width: 100%;
    background: $white;
    border-radius: 12px;
    box-shadow: $multi-shadow;
  }

  &__item {
    line-height: 1.5;
    white-space: nowrap;
    padding: 10px 24px;
    border-bottom: 1px solid rgba($black, 0.2);

    &:hover {
      background: $grey-350;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.toggle {
  position: absolute;
  right: 1%;
  padding: 0 0.5rem;
  background: transparent;
  display: flex;
  align-items: center;
  height: 100%;

  &_open {
    svg {
      transform: rotate(180deg);
    }
  }
}

.left {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.right {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  transform: translateX(-1px);
}
