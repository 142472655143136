@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.footer {
  padding: 1.5rem 0;
  background: #EAE8E8;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1rem));
  column-gap: 2rem;
  grid-template-areas:  "logo nav"
                        "policy nav"
}


.logo {
  grid-area: logo;
}

.policy {
  margin-top: auto;
  grid-area: policy;
  width: 80%;
  line-height: 22.4px;
}

.nav {
  grid-area: nav;
  list-style: none;

  &__title {
    font-weight: $weight-bolder;
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

  &__item {
    font-weight: $weight-bold;
    line-height: 22.4px;
    cursor: pointer;
  }
}

@include media("within", "sm") {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    grid-template-areas:  "logo"
                          "nav"
                          "policy"
  }

  .logo {
    margin-bottom: 2rem;
  }

  .nav {

    margin-bottom: 1.75rem;

    &__title {
      margin-bottom: 0.75rem;
      font-size: 1.25rem;
      font-weight: $weight-bold;
    }

    &__list {

    }

    &__item {
      font-weight: $weight-regular;
    }
  }

  .policy {
    width: 100%;
    font-weight: $weight-bold;
    font-size: 12px;
    line-height: 16.8px;
    text-align: center;
    color: $grey-600;
  }

  .footer_hidden {
    display: none;
  }
}
