@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
  &:last-of-type .item {
    border: none;
  }
}

.submenu {
  height: 0;
  overflow: hidden;
}

.item {
  display: block;
  font-size: rem(20);
  font-weight: $weight-bold;
  padding: rem(32) rem(25) rem(24) rem(32);
  border-bottom: 1px solid $white;

  &:hover, &.active {
    box-shadow: 0 4px 10px 2px #0000001A inset, 0 -4px 10px 2px #0000001A inset;
  }

  &.active {
    background: #124673;
    color: $white;
    &+.submenu {
      height: auto;
      overflow: auto;
    }
  }
}
