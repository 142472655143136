@import "styles/mixin";

.logo {
  position: relative;
  display: block;
  width: 148px;
  height: 26px;
  margin-right: 131px;
}

@include media("within", "sm") {
  .logo {
    width: 184px;
    height: 38px;
  }
}