@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.footer {
    padding: 1.5rem 0;
    background: $black;
    color: $white;
}

.wrapper {
    display: grid;
    justify-items: stretch;
    grid-template-columns: calc(40% - 2rem) calc(60% - 2rem);
    column-gap: 2rem;
    grid-template-areas: "logo nav"
        "policy nav"
}


.logo {
    grid-area: logo;
}

.policy {
    margin-top: auto;
    grid-area: policy;
    width: 80%;
    font-size: 1.125rem;
        line-height: 1.4;
}

.nav {
    grid-area: nav;
    list-style: none;

    &__title {
        font-size: 1.55rem;
        margin-bottom: 1rem;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 30%);
        row-gap: 7px;
        column-gap: 1rem;
    }

    &__item {
        font-size: 1.125rem;
        line-height: 1.4;
        cursor: pointer;
    }
}

.contactBtn {
    border: none;
    background: none;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    padding: 0;

    &:focus {
        outline: 1px dotted;
    }
}

@include media("within", "sm") {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 2rem;
        grid-template-areas: "logo"
            "nav"
            "policy";
        max-width: 100%;
        overflow: hidden;
    }

    .logo {
        margin-bottom: 2rem;
    }

    .nav {
        margin-bottom: 1.75rem;
    }

    .policy {
        width: 100%;
        color: rgba($white, 0.8);
    }

    .footer_hidden {
        display: none;
    }
}


@media screen and (max-width: 599px) {
    .nav__list {
        grid-template-columns: repeat(2, 45%);
    }
}


@media screen and (max-width: 380px) {
    .nav__list {
        grid-template-columns: repeat(2, 1fr);
    }
}
