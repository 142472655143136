@import "styles/colors";
@import "styles/mixin";
.overlayHidden {
  display: none;
}
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
  z-index: 999;
}

@include media("within", "md") {
  .overlay {
    overflow: auto;
  }
}
@include media("within", "sm") {
  .overlay {
    overflow: auto;
  }
}