
@import "colors";

.splide__pagination__page {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background: $white;
  border: none;
  margin-right: 11px;
  margin-top: 2.75rem;
  padding: 0;

  &--small {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 12px;
  }
}

.splide__pagination__page.is-active {
  background: $blue;
}

.splide__pagination__page--small.is-active {
    width: 0.5rem;
    height: 0.5rem;

}
