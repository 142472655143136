@import "./normalize";
@import "fontface";
@import "mixin";
@import "colors";
@import "gallery";
@import "form";
@import "nprogress";
@import "phone";
@import "./fonts";

html,
body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-family: $main-font;
    font-size: 16px;
    background: #EEEEEE;
}

body {
    display: flex;
}

#__next {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
}

main {
    padding-bottom: 6rem;
    background: #EEEEEE;
    min-height: calc(100vh - 58px);
    flex-basis: 100%;
    flex-grow: 1;
}

a {
    color: inherit;
    text-decoration: none;
    transition: all 0.15s ease-out;
}

ul,
menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

* {
    box-sizing: border-box;
}

.container {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding: 0 1.25rem;

    @include media("max", "sm") {
        padding: 0 1rem;
    }
}

.container-full {
    width: 100%;
    margin: 0;
    padding: 0 2rem;
}

*:focus {
    outline: none;
}

.h1,
h1 {
    color: $grey-575;
    margin: 1rem 0;
    font-size: clamp(1.5rem, 1.25rem + 1.25vw, 2.275rem);
    line-height: 1.2;
    font-weight: $weight-bold;
    word-break: break-all;
}

.h2,
h2 {
    color: #212121;
    font-weight: 600;
    font-size: clamp(1rem, 0.8571rem + 0.7143vw, 1.5rem);
    line-height: 1;
    margin: 18px 0;
    word-break: break-all;
}

.admin_area {

    & h1,
    & h2 {
        margin: 0;
        font-size: rem(34);
    }
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeletonBorder {
    animation: skeleton-border 1s linear infinite alternate;
}

ins[data-ad-status="unfilled"] {
    display: none !important;
}

@keyframes skeleton-loading {
    0% {
        background-color: #DFE9F3;
    }

    100% {
        background-color: #efefef;
    }
}

@keyframes skeleton-border {
    0% {
        border-color: #DFE9F3;
    }

    100% {
        border-color: #efefef;
    }
}
