@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    min-width: 240px;
    background: $white;
    overflow: hidden;
    box-shadow: $multi-shadow;
    z-index: 5;
    @media screen  and (min-width: 992px) {
        animation: openModal 0.4s ease-out forwards;
        border-radius: 20px;
    }
}

@keyframes openModal {
    0% { transform: translateX(20px); }
    100% { transform: translateX(0);   }
}

.menu {
    margin: 0;
    list-style: none;
    line-height: 2rem;
    color: $grey-700;

    &_hidden {
        display: none;
    }

    &__item {
        border-bottom: 1px solid $grey-150;

        &:last-child {
            border-bottom: none;
        }
        a,
        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 12px 16px;
            line-height: 24px;
            background: transparent;
            border: none;
            color: inherit;
            transition: 0.15s all ease-out;

            &:hover {
                background: $blue;
                color: $white;
                transition: all 0.1s ease-in-out;
            }
        }

        &_active {
            color: $white;
            background: $blue;
        }
    }
}

.toggle {
    display: none;
}

.open {
    line-height: 1.75rem;
    list-style: none;
    border: 1px solid $blue;
    border-radius: 0 0 13px 13px;
    overflow: hidden;
    background: $white;
    text-align: center;
    color: $blue;
    font-weight: $weight-bolder;
}

.hide {
    margin-top: 2rem;
}

.toggle {
    user-select: none;
    cursor: pointer;
    width: 100%;
    display: none;
    justify-content: center;

    &__name {
        margin-right: 4px;
    }

    &__icon_rev {
        transform: rotate(180deg);
    }
}

@include media("max", "md") {

    .toggle {
        display: flex;
    }

    .menu__item {
        display: flex;
        align-items: stretch;
        &>a,
        &>button  {
            width: 100%;
        }
    }

}

@include media("max", "md") {
    .toggle {
        display: flex;
    }

    .container {
        position: static;
        width: 100%;
        background: transparent;
        box-shadow: none;
    }

    .menu {
        flex-direction: column;
        line-height: 2;
    }

}
