@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.wrapper {
    display: flex;
    align-items: center;
    gap: rem(8);
    padding: 1rem 0;
}

.addButton,
.favoritesButton {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.2rem;
    border: none;
    background: transparent;
}


.burger {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    padding: 0.5rem;
}

@keyframes openModal {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}

.nav {
    display: none;
    position: relative;
    line-height: 1rem;

    &_show {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: $white;
        z-index: 104;
        overflow-y: auto;

        animation: openModal 0.4s ease-out forwards;
    }

    &__close {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        padding: rem(12) rem(16);
        background: transparent;
        z-index: 2;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0;
        list-style: none;
    }

    &__title {
        width: 100%;
        font-size: rem(22);
        line-height: 1.5rem;
        margin-right: 0;
        padding: rem(12) rem(16);
        background: $blue;
        color: $white;
    }

    &__item {
        width: 100%;
        line-height: 1.5rem;

    }

    &__menuMobile {
        display: block;
        width: 100%;

        &+.nav__item {
            border-top: 2px solid $grey-150;
        }
    }

    &__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 16px;
        line-height: 24px;
        background: transparent;
        border: none;
        color: inherit;
        transition: 0.15s all ease-out;
    }
}
