@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2%;
    padding: rem(30) 0 1rem 0;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: $multi-shadow;
    border-radius: 96px;
}

.input_containers {
    position: relative;
    display: flex;
    background-color: white;
    height: rem(46);
    align-items: center;
    padding-left: rem(40);

    input {
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        padding-left: rem(16);
        font-size: rem(18);
        font-weight: $weight-medium;
        color: #404040;

        &:focus {
            outline: none;
        }

        &::placeholder {
            font-size: rem(18);
            font-weight: $weight-bold;
            color: #404040;
        }
    }
}

.search_container {
    @extend .input_containers;
    width: calc(100% - 256px * 2);
    border-radius: rem(96) 0 0 rem(96);


    svg {
        transform: rotateY(180deg);
    }

    input {
        &::placeholder {
            font-size: rem(18);
            font-weight: $weight-medium;
            color: #BBBBBB;
        }
    }

    & .drawer {
        width: calc(100% + 2px);
        right: -1px;
    }
}

.categories_container {
    @extend .input_containers;
    width: rem(256);
    border: 1px solid rgba($black, 0.2);
    border-width: 0 1px 0 1px;
    padding-right: rem(40);

    &::before {
        content: "";
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9InBhdGgtMS1pbnNpZGUtMV8xNzFfNDMzNyIgZmlsbD0id2hpdGUiPgo8cmVjdCB3aWR0aD0iMTEuMDc2OSIgaGVpZ2h0PSIxMS4wNzY5IiByeD0iMSIvPgo8L21hc2s+CjxyZWN0IHdpZHRoPSIxMS4wNzY5IiBoZWlnaHQ9IjExLjA3NjkiIHJ4PSIxIiBzdHJva2U9IiNEODM1MkIiIHN0cm9rZS13aWR0aD0iNCIgbWFzaz0idXJsKCNwYXRoLTEtaW5zaWRlLTFfMTcxXzQzMzcpIi8+CjxtYXNrIGlkPSJwYXRoLTItaW5zaWRlLTJfMTcxXzQzMzciIGZpbGw9IndoaXRlIj4KPHJlY3QgeD0iMTIuOTIzOCIgd2lkdGg9IjExLjA3NjkiIGhlaWdodD0iMTEuMDc2OSIgcng9IjEiLz4KPC9tYXNrPgo8cmVjdCB4PSIxMi45MjM4IiB3aWR0aD0iMTEuMDc2OSIgaGVpZ2h0PSIxMS4wNzY5IiByeD0iMSIgc3Ryb2tlPSIjRDgzNTJCIiBzdHJva2Utd2lkdGg9IjQiIG1hc2s9InVybCgjcGF0aC0yLWluc2lkZS0yXzE3MV80MzM3KSIvPgo8bWFzayBpZD0icGF0aC0zLWluc2lkZS0zXzE3MV80MzM3IiBmaWxsPSJ3aGl0ZSI+CjxyZWN0IHk9IjEyLjkyMjkiIHdpZHRoPSIxMS4wNzY5IiBoZWlnaHQ9IjExLjA3NjkiIHJ4PSIxIi8+CjwvbWFzaz4KPHJlY3QgeT0iMTIuOTIyOSIgd2lkdGg9IjExLjA3NjkiIGhlaWdodD0iMTEuMDc2OSIgcng9IjEiIHN0cm9rZT0iI0Q4MzUyQiIgc3Ryb2tlLXdpZHRoPSI0IiBtYXNrPSJ1cmwoI3BhdGgtMy1pbnNpZGUtM18xNzFfNDMzNykiLz4KPG1hc2sgaWQ9InBhdGgtNC1pbnNpZGUtNF8xNzFfNDMzNyIgZmlsbD0id2hpdGUiPgo8cmVjdCB4PSIxMi45MjM4IiB5PSIxMi45MjI5IiB3aWR0aD0iMTEuMDc2OSIgaGVpZ2h0PSIxMS4wNzY5IiByeD0iMSIvPgo8L21hc2s+CjxyZWN0IHg9IjEyLjkyMzgiIHk9IjEyLjkyMjkiIHdpZHRoPSIxMS4wNzY5IiBoZWlnaHQ9IjExLjA3NjkiIHJ4PSIxIiBzdHJva2U9IiNEODM1MkIiIHN0cm9rZS13aWR0aD0iNCIgbWFzaz0idXJsKCNwYXRoLTQtaW5zaWRlLTRfMTcxXzQzMzcpIi8+Cjwvc3ZnPgo=");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &::after {
        content: "";
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMkw4IDhMMTQgMiIgc3Ryb2tlPSIjNTk1OTU5IiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    & input {
        color: white;
        text-shadow: 0 0 0 $grey-600;
        cursor: pointer;
        -webkit-text-fill-color: transparent;

    }

    & .drawer {
        width: calc(100% + 2px);
        right: -1px;
    }
}

.autocomplete {
    position: absolute;
    top: rem(36);
    background-color: $white;
    width: calc(100% - 36px);
    right: 0;
    padding: 5px;
    z-index: 3;

    div {
        padding: 5px;
        cursor: pointer;
    }
}

.selected {
    background: $grey-200;
}

.city_container {
    @extend .input_containers;
    border-radius: 0 rem(96) rem(96) 0;
    width: rem(256);
    padding-right: rem(20);

    &::before {
        content: "";
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyMyAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjA2OSAyNUg5LjU5NzI0QzguOTQ4OSAyNSA4LjM0MDc5IDI0LjY4NTcgNy45NjU3NyAyNC4xNTY5TDEuMzY4NTMgMTQuODUzMUMxLjEyODc4IDE0LjUxNDkgMSAxNC4xMTA3IDEgMTMuNjk2MlY3LjA5MjEyQzEgNi42MDMyNiAxLjE3OTA1IDYuMTMxMzMgMS41MDMzMSA1Ljc2NTVMNS4xMzA0MSAxLjY3MzM4QzUuNTA5OTkgMS4yNDUxNCA2LjA1NDg0IDEgNi42MjcxIDFIMTYuOTg0NkMxNy42MzI5IDEgMTguMjQxIDEuMzE0MjcgMTguNjE2IDEuODQzMTRMMjEuNDMxNSA1LjgxMzYxQzIxLjY3MTIgNi4xNTE3MiAyMS44IDYuNTU1OTcgMjEuOCA2Ljk3MDQ2VjEzLjc2MzNDMjEuOCAxNC4xMzU5IDIxLjY5NTkgMTQuNTAxMSAyMS40OTk1IDE0LjgxNzdMMTUuNzY4NSAyNC4wNTQ0QzE1LjQwMzcgMjQuNjQyNCAxNC43NjA5IDI1IDE0LjA2OSAyNVoiIHN0cm9rZT0iI0Q4MzUyQiIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0xMS40ODk1IDE0Ljg0NkgxMS4zMTA1QzkuMjcxMjcgMTQuODQ2IDcuNjE4MTYgMTMuMTkyOSA3LjYxODE2IDExLjE1MzdDNy42MTgxNiA5LjExNDUzIDkuMjcxMjcgNy40NjE0MyAxMS4zMTA1IDcuNDYxNDNIMTEuNDg5NUMxMy41Mjg3IDcuNDYxNDMgMTUuMTgxOCA5LjExNDUzIDE1LjE4MTggMTEuMTUzN0MxNS4xODE4IDEzLjE5MjkgMTMuNTI4NyAxNC44NDYgMTEuNDg5NSAxNC44NDZaIiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjRDgzNTJCIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    & .drawer {
        width: calc(100% + 1px);
    }
}

.button_container {
    width: rem(200);
}

@media screen and (max-width: 1200px) {
    .search {
        padding-top: rem(33);
    }
    .input_containers {
        flex-basis: 33%;
    }
}
@include media("within", "md") {
    .search {
        padding-top: rem(33);
    }
    .input_containers {
        padding-left: rem(19);
    }

    .categories_container {
        padding-right: rem(20);
    }

}

@include media("within", "sm") {
    .search {
        flex-wrap: wrap;
        padding-top: rem(33);
    }

    .container {
        flex-direction: column;
        margin-bottom: rem(18);
    }

    .input_containers {
        flex-basis: 100%;
        width: 100%;
        border: none;
        padding-left: rem(19);

        input {
            height: rem(46);
        }

        & .drawer {
            width: calc(100%);
            right: 0;
            top: rem(55);
            min-height: rem(46);
        }
    }

    .search_container {
        border-radius: rem(20) rem(20) 0 0;
        input {
            padding-left: 0;
        }

    }

    .categories_container {
        border: 1px solid rgba($black, 0.2);
        border-width: 1px 0 1px 0;
        padding-right: rem(30);
    }

    .city_container {
        border-radius: 0 0 rem(20) rem(20);
        border: none;

        & .drawer {
            top: rem(43);
        }
    }

    .button_container{
        width: 100%;
    }
}
