@import "styles/colors";
@import "styles/mixin";
@import "styles/fonts";
.container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  top: rem(20);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(20);
}
.alert {
  padding: rem(20);
  background: $white;
  width: fit-content;
  min-width: 400px;
  border-radius: rem(20);
  border: 3px solid currentColor;
  text-align: center;
  color: $dark-blue;
  font-size: rem(18);
  font-weight: $weight-bold;
  box-shadow: $multi-shadow;

  &.error {
    color: $red-dark;
  }

  &.success{
    color: $blue;
  }
}
