@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
  width: rem(337);
  background: #DEDEDE;
}

.depth-1 {
  background-color: $grey-300;
  border-bottom: 1px solid $white;
  &:nth-child(1n){
    border-left: rem(15) solid #DEDEDE;
  }
}
