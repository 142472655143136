@import "styles/colors";
@import "styles/mixin";

.header {
    background: $white;
    &__top {
        height: 4.5rem;
    }
}

